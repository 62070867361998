<template>
  <div class="mainContent" v-loading="loading">
    <div class="top">
      <div class="logo">
        <img src="../../../assets/dayControl/logo.png" style="height: 46px;"/>
        <span>日周月监管 / 日管控 / {{data.elevatorNO}}</span>
      </div>
      <div class="elevatorInfo">
        <span>{{data.elevatorName}} / 日管控报告</span>
        <div class="report">
          <router-link style="color: #fff" :to="'/dayControl/' + data.id" target="_blank">详细报告 ></router-link>
        </div>
      </div>
      <!-- <div class="topLeft">
        <img style="float: left; margin-top: 17px" src="../../../assets/dayControl/logo.png"/>
        电梯运行健康日管控总览/{{ data.elevatorName }}
      </div>
      <div class="topRight">
        <router-link style="color: #fff" :to="'/dayControl/' + data.id" target="_blank">详细报告</router-link>
      </div> -->
    </div>
    <div class="bottom">
      <el-row :gutter="16">
        <el-col :lg="16">
          <div class="left">
            <div class="imgBox">
              <img src="../../../assets/dayControl/3d_电梯底图.png"/>
            </div>
            <div class="imgBox">
              <img src="../../../assets/dayControl/测点_正常全图.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('控制柜') == '异常'">
              <img src="../../../assets/dayControl/测点_控制柜.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('控制柜') == '异常'">
              <img src="../../../assets/dayControl/3d_控制柜.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('驱动主机') == '异常'">
              <img src="../../../assets/dayControl/测点_驱动主机.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('驱动主机') == '异常'">
              <img src="../../../assets/dayControl/3d_驱动主机.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('抱闸') == '异常'">
              <img src="../../../assets/dayControl/测点_抱闸.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('抱闸') == '异常'">
              <img src="../../../assets/dayControl/3d_抱闸.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('门') == '异常'">
              <img src="../../../assets/dayControl/测点_门.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('门') == '异常'">
              <img src="../../../assets/dayControl/3d_门.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('编码器') == '异常'">
              <img src="../../../assets/dayControl/测点_编码器.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('编码器') == '异常'">
              <img src="../../../assets/dayControl/3d_编码器.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('平层开关') == '异常'">
              <img src="../../../assets/dayControl/测点_平层开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('平层开关') == '异常'">
              <img src="../../../assets/dayControl/3d_平层开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('减速开关') == '异常'">
              <img src="../../../assets/dayControl/测点_减速开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('减速开关') == '异常'">
              <img src="../../../assets/dayControl/3d_减速开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('门区开关') == '异常'">
              <img src="../../../assets/dayControl/测点_门区开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('门区开关') == '异常'">
              <img src="../../../assets/dayControl/3d_门区开关.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('钢丝绳') == '异常'">
              <img src="../../../assets/dayControl/测点_钢丝绳.png"/>
            </div>
            <div class="imgBox" v-if="getItemValue('钢丝绳') == '异常'">
              <img src="../../../assets/dayControl/3d_钢丝绳.png"/>
            </div>
            <div class="otherBox">
              <div class="item">
                <div class="itemTitle">安全回路</div>
                <img v-if="getItemValue('安全回路') == '异常'" src="../../../assets/dayControl/异常.png"/>
                <img v-else src="../../../assets/dayControl/正常.png"/>
              </div>
              <div class="item">
                <div class="itemTitle">通讯</div>
                <img v-if="getItemValue('通讯') == '异常'" src="../../../assets/dayControl/异常.png"/>
                <img v-else src="../../../assets/dayControl/正常.png"/>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8">
          <div class="right">
            <div class="records">
              <div class="title">
                <div class="line"></div>
                事件记录
              </div>
              <div class="content" v-if="eventRecords.length">
                <div class="recordItem" v-for="(item, index) in eventRecords" :key="index">
                  <div><span>时间：</span>{{ item.eventTime }}</div>
                  <div><span>描述：</span>{{ item.desc }}</div>
                </div>
              </div>
              <div class="content" v-else>
                <el-empty style="height: 100%" :image-size="100" description="未见事件"></el-empty>
              </div>
            </div>
            <div class="records">
              <div class="title"><div class="line"></div>故障记录</div>
              <div class="content" v-if="faultRecords.length">
                <div class="recordItem" v-for="(item, index) in faultRecords" :key="index">
                  <div><span>时间：</span>{{ item.faultTime }}</div>
                  <div><span>故障码：</span>{{ item.faultCode }}</div>
                  <div><span>描述：</span>{{ item.faultDesc }}</div>
                </div>
              </div>
              <div class="content" v-else>
                <el-empty style="height: 100%" :image-size="100" description="未见故障"></el-empty>
              </div>
            </div>
            <div class="records">
              <div class="title"><div class="line"></div>告警记录</div>
              <div class="content" v-if="otherRecords.length">
                <div class="recordItem" v-for="(item, index) in stopRecords" :key="index">
                  <div><span>告警时间：</span>{{ item.alarmTime }}</div>
                  <div><span>告警类型：</span>停梯</div>
                  <div><span>状态：</span>
                      <span v-if="item.recordState == 0">报警</span>
                      <span v-else-if="item.recordState == 1">接警</span>
                      <span v-else-if="item.recordState == 2">到场</span>
                      <span v-else-if="item.recordState == 3">完工</span>
                      <span v-else-if="item.recordState == 4">确认</span>
                      <span v-else-if="item.recordState == 5">撤销</span>
                      <span v-else-if="item.recordState == 8">自动确认</span>
                  </div>
                  <div><span>持续时间：</span>{{diffTime(item.finishTime,item.alarmTime)}}</div>
                </div>
                <div class="recordItem" v-for="(item, index) in trappedRecords" :key="index">
                  <div><span>告警时间：</span>{{ item.alarmTime }}</div>
                  <div><span>告警类型：</span>困人</div>
                  <div><span>状态：</span>
                      <span v-if="item.recordState == 0">报警</span>
                      <span v-else-if="item.recordState == 1">接警</span>
                      <span v-else-if="item.recordState == 2">到场</span>
                      <span v-else-if="item.recordState == 3">完工</span>
                      <span v-else-if="item.recordState == 4">确认</span>
                      <span v-else-if="item.recordState == 5">撤销</span>
                      <span v-else-if="item.recordState == 8">自动确认</span>
                  </div>
                  <div><span>持续时间：</span>{{diffTime(item.finishTime,item.alarmTime)}}</div>
                </div>
              </div>
              <div class="content" v-else>
                <el-empty style="height:100%" :image-size="100" description="未见告警"></el-empty>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      equipmentList: [],
      faultRecords: [],
      eventRecords: [],
      stopRecords: [],
      trappedRecords: [],
      otherRecords: [],
      data: {},
    };
  },

  computed: {
    getItemValue() {
      return function (itemName) {
        if (this.equipmentList.length) {
          let result = this.equipmentList.find((item) => item.itemName === itemName);
          if (result) {
            return result.itemValue;
          }
        }
      };
    },

    diffTime() {
      return function (finishTime,alarmTime) {
        let diffSeconds;
        if(finishTime) {
          diffSeconds = (this.$moment(finishTime).valueOf() - this.$moment(alarmTime).valueOf()) / 1000;
        } else {
          diffSeconds = (this.$moment().valueOf() - this.$moment(alarmTime).valueOf()) / 1000;
        }
        let duration = this.$moment.duration(diffSeconds,"seconds");
          let days = duration.days()?duration.days() + "天":"";
          let hours = duration.hours()?duration.hours() + "小时":"";
          let minutes = duration.minutes()?duration.minutes() + "分":"";
          let seconds = duration.seconds()?duration.seconds() + "秒":"";
          return days+ hours  + minutes  + seconds ;
      };
    }
  },

  mounted() {
    this.getData(this.$route.params.id);
  },

  methods: {
    getData(id) {
      this.loading = true;
      this.$http.get(`periodicInspectionBill/${id}`).then((res) => {
        this.data = res.data;
        this.equipmentList = res.data.results.filter((item) => item.itemType == "设备");
        this.faultRecords = res.data.faultRecords;
        this.trappedRecords = res.data.trappedRecords;
        this.stopRecords = res.data.stopRecords;
        this.otherRecords = res.data.trappedRecords.concat(res.data.stopRecords);
        this.eventRecords = res.data.events;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  height: 100%;
  position: relative;
  background-color: #F2F2F2;
  .top {
    margin: 0 32px;
    .logo {
      height: 56px;
      display: flex;
      align-items: center;
      color: #4D4D4D;
      font-size: 12px;
    }
    .elevatorInfo {
      margin: 10px 0;
      font-size: 20px;
      color: #0747FD;
      line-height: 26px;
      position: relative;
      .report {
        width: 96px;
        background: #0747FD;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 8px;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
  .bottom {
    left: 32px;
    right: 32px;
    top: 102px;
    bottom: 32px;
    position: absolute;
    .el-row,
    .el-col {
      height: 100%;
    }
    .left {
      height: 100%;
      text-align: center;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 50%, #e6e6e6 100%);
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
      border-radius: 12px 12px 12px 12px;
      position: relative;
      .imgBox {
        width: 100%;
        height: 100%;
        position: absolute;
        img {
          height: 100%;
        }
      }
      .otherBox {
        width: 208px;
        height: 120px;
        position: absolute;
        bottom: 5%;
        left: 10%;
        display: flex;
        justify-content: space-between;
        .item {
          width: 96px;
          height: 100%;
          background-color: #fff;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #cccccc;
          .itemTitle {
            line-height: 38px;
            border-bottom: 1px solid #cccccc;
          }
        }
      }
    }
    .right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .records {
        height: 32%;
        border-radius: 12px 12px 12px 12px;
        box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        .title {
          border-bottom: 1px solid #f2f2f2;
          line-height: 40px;
          padding-left: 10px;
          display: flex;
          font-size: 16px;
          .line {
            width: 4px;
            height: 16px;
            background-color: #0747FD;
            border-radius: 100px;
            margin-right: 8px;
            margin-top: 12px;
          }
        }
        .content {
          flex: 1;
          overflow: auto;
          .recordItem {
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            margin: 0 22px;
            line-height: 20px;
            span {
              color: #808080;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>
